@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */

/* Import Bulma CSS if not already imported elsewhere */
/* If Bulma is imported via a CDN or in another file, you can remove this import */

/* General Styles */
body {
  background-color: #f0f2f5;
  /* Light background for better contrast */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Enhance the Tabs Container */
.tabs {
  margin-bottom: 20px;
  justify-content: center;
  /* Center the tabs horizontally */
}

/* Style Each Tab Link */
.tabs ul li a {
  background-color: #f5f5f5;
  /* Light gray background */
  color: #363636;
  /* Darker text color for better readability */
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
  transition: background-color 0.3s, color 0.3s;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  cursor: pointer;
}

/* Remove default Bulma tab border */
.tabs ul li a {
  border: none;
}

/* Hover Effect for Tab Links */
.tabs ul li a:hover {
  background-color: #e0e0e0;
  /* Slightly darker gray on hover */
  color: #000000;
  /* Black text on hover */
}

/* Active Tab Styling */
.tabs ul li.is-active a {
  background-color: #00d1b2;
  /* Teal background for active tab */
  color: #ffffff;
  /* White text for contrast */
  border-radius: 5px;
  font-weight: bold;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tabs ul li a {
    padding: 8px 12px;
    font-size: 14px;
  }
}

/* Sticky Summary Box */
.sticky-summary {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

/* Modal Customization (if needed) */
.modal-content .box {
  padding: 20px;
  border-radius: 8px;
}

/* Adjust buttons spacing */
.buttons>.button {
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Table Styles */
.table th,
.table td {
  vertical-align: middle;
}

/* Optional: Adjust Input Field Styles */
.input {
  height: 40px;
  font-size: 16px;
}

/* Optional: Adjust button styles for better visibility */
.button.is-primary {
  background-color: #00d1b2;
  border-color: transparent;
}

.button.is-danger {
  background-color: #ff3860;
  border-color: transparent;
}

.button.is-info {
  background-color: #209cee;
  border-color: transparent;
}

.button.is-warning {
  background-color: #ffdd57;
  border-color: transparent;
}

/* Ensure the modal is above other elements */
.modal {
  z-index: 50;
}

/* Additional Custom Styles */

/* Form Section Background */
.section.has-background-light {
  background-color: #ffffff;
  /* White background for sections */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Summary Box Styling */
.box.sticky-summary {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Modal Box Styling */
.modal-content .box {
  max-width: 400px;
}

/* Adjust title sizes for better hierarchy */
.title.is-4 {
  margin-bottom: 15px;
}

.title.is-5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
